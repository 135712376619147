import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/vercel/path0/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactFlagButton"] */ "/vercel/path0/components/contact-flag-button/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAnalyticsPageView"] */ "/vercel/path0/components/google-analytics-page-view/GoogleAnalyticsPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/components/internalComponents/header/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/vercel/path0/components/pageComponents/footer/footer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.6_next@14.2.5_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__reac_jbtgommwxi3qyayfcbxdhqruxi/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.6_next@14.2.5_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__reac_jbtgommwxi3qyayfcbxdhqruxi/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.6_next@14.2.5_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__reac_jbtgommwxi3qyayfcbxdhqruxi/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.6/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.6/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--inter-font\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.6/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/neue-plak-comp-black.woff2\",\"display\":\"swap\",\"variable\":\"--neue-plak-font\"}],\"variableName\":\"neuePlak\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.scss");
