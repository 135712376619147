'use client';
import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { pageview } from '@/helpers/gtag';

export const GoogleAnalyticsPageView = () => {
  const pathname = usePathname();

  useEffect(() => {
    if (!pathname) return;
    pageview(pathname);
  }, [pathname]);
  return null;
};
