import { AbstractButton } from '../abstractButton/abstractButton';
import styles from './burger.module.scss';

type BurgerProps = {
  onClick?: () => void;
  isOpen: boolean;
  surface: 'neutral' | 'inverted';
};

export const Burger = ({ onClick, isOpen, surface, ...props }: BurgerProps) => (
  <AbstractButton
    {...props}
    onClick={onClick}
    className={`${styles.wrapper} ${styles.circle} ${styles[surface]} ${
      isOpen ? styles.open : ''
    }`}
  >
    <div className={`${styles.bar} ${styles.topBar}`} />
    <div className={`${styles.bar} ${styles.centerBar}`} />
    <div className={`${styles.bar} ${styles.bottomBar}`} />
  </AbstractButton>
);
