'use client';

import classNames from 'classnames';
import { useScrollPosition } from 'hooks/useScrollPosition';
import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import { Address, MenuItem as NavItem } from '@/types/misc';

import { AbstractButton } from '../abstractButton/abstractButton';
import { Icon } from '../icon';
import { Burger } from './burger';
import styles from './header.module.scss';
import { MenuItem } from './menuItem';
import { SlideInMenu } from './slideInMenu';

export interface HeaderProps {
  surface: 'neutral' | 'inverted';
  menuItems: NavItem[];
  extendedMenuItems: NavItem[];
  address: Address;
}

export const Header = ({
  surface,
  menuItems,
  extendedMenuItems,
  address,
}: HeaderProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scrollPosition = useScrollPosition();
  const slug = usePathname();
  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [slug]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!document.body.classList.contains('overflow-hidden')) {
      document.body.classList.add('overflow-hidden');
    } else if (document.body.classList.contains('overflow-hidden')) {
      document.body.classList.remove('overflow-hidden');
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    if (document.body.classList.contains('overflow-hidden')) {
      document.body.classList.remove('overflow-hidden');
    }
  };

  const handleWindowScroll = useCallback(() => {
    setIsHidden(
      (window.scrollY > 0 && window.scrollY >= lastScrollY) ||
        (document.body.getAttribute('style') &&
          document.body.getAttribute('style')?.indexOf('position: fixed') != -1)
        ? true
        : false
    );
    setLastScrollY(window.scrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, [lastScrollY, handleWindowScroll]);

  const scrolledBg =
    !isMenuOpen && scrollPosition !== undefined && scrollPosition > 100;

  const navItems = menuItems.map(({ id, attributes, vacancyCount }) => (
    <MenuItem
      key={id}
      name={attributes.title}
      href={attributes.slug}
      amount={vacancyCount ?? 0}
      surface={scrolledBg ? 'neutral' : surface}
      type="navigation"
      onClick={closeMenu}
    />
  ));

  return (
    <>
      <div
        className={classNames(styles.headerWrapper, styles[surface], {
          [styles.open]: scrolledBg,
          [styles.isHidden]: isHidden,
        })}
      >
        <nav className={styles.navigation}>
          <AbstractButton
            href="/"
            title="Naar de Homepagina"
            onClick={closeMenu}
          >
            <Icon name="Logo" />
          </AbstractButton>
          <div className={styles.menuWrapper}>
            <ul className={styles.navList}>{navItems}</ul>
            <Burger
              aria-label="Open het menu"
              surface={scrolledBg ? 'neutral' : surface}
              isOpen={isMenuOpen}
              onClick={toggleMenu}
            />
          </div>
        </nav>
      </div>
      <SlideInMenu
        isOpen={isMenuOpen}
        extendedMenuItems={extendedMenuItems}
        address={address}
        closeMenu={closeMenu}
      />
    </>
  );
};
