'use client';

import { formatPhoneNumber } from 'helpers/formatPhoneNumber';
import { Address, MenuItem as NavItem, SocialItem } from '@/types/misc';

import { LinkButton, SimpleButton } from 'components/buttons';

import { MenuItem } from '../../internalComponents/header/menuItem';
import { Icon } from '../../internalComponents/icon';
import { Container } from '../container/container';
import styles from './footer.module.scss';

export interface FooterProps {
  direction?: 'up' | 'down';
  menuItems: NavItem[];
  privacyMenuItems: NavItem[];
  socialMenuItems: SocialItem[];
  address: Address;
  localeSwitch?: string;
}

export const Footer = ({
  direction = 'up',
  menuItems,
  privacyMenuItems,
  socialMenuItems,
  address,
}: FooterProps) => {
  return (
    <footer className={`${styles.footer} ${styles[direction]}`}>
      <Container size="wide" className={styles.footerContent}>
        <div className={styles.jsLove}>
          <SimpleButton href="/" aria-label="Naar de Homepagina">
            <Icon name="Logo" />
          </SimpleButton>
          <Icon name="Heart" className={styles.heart} />
          Javascript
        </div>
        <nav className={styles.menuWrapper}>
          <ul className={styles.menu}>
            {menuItems.map(({ id, attributes }) => (
              <MenuItem
                key={id}
                name={attributes.title}
                href={attributes.slug}
                surface="inverted"
                type="footer"
              />
            ))}
          </ul>
        </nav>
        <div className={styles.socials}>
          {socialMenuItems.map((socialItem) => (
            <SimpleButton href={socialItem.link} key={socialItem.id}>
              <Icon name={socialItem.icon} />
            </SimpleButton>
          ))}
        </div>

        <div className={styles.addressWrapper}>
          <address className={styles.address}>
            <span>{address.addressLine1}</span>
            <span>{address.addressLine2}</span>
          </address>
          <div className={styles.address}>
            <LinkButton
              style={{ type: 'primary', inverted: true }}
              href={`tel:${address.phoneNumber}`}
            >
              {formatPhoneNumber(address.phoneNumber)}
            </LinkButton>
            <LinkButton
              style={{ type: 'primary', inverted: true }}
              href={`mailto:${address.emailAddress}`}
            >
              {address.emailAddress}
            </LinkButton>
          </div>
        </div>

        <div className={styles.privacy}>
          {privacyMenuItems.map((privacyMenuItem) => (
            <LinkButton
              style={{ type: 'secondary', inverted: true }}
              key={privacyMenuItem.id}
              href={privacyMenuItem.attributes.slug}
            >
              {privacyMenuItem.attributes.title}
            </LinkButton>
          ))}
        </div>
      </Container>
    </footer>
  );
};
