'use client';
import { Inter } from 'next/font/google';
import local from 'next/font/local';
import { useEffect, useState } from 'react';
import { Drawer } from 'vaul';
import { useRouter, usePathname } from 'next/navigation';
import { GetInTouch } from 'components/get-in-touch';
import { Typography } from 'components/typography';

import { IconButton } from '../commonComponents/iconButton/iconButton';
import { Icon } from '../internalComponents/icon';
import styles from './styles.module.scss';

const inter = Inter({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  variable: '--inter-font',
  display: 'swap',
});

const neuePlak = local({
  src: '../../fonts/neue-plak-comp-black.woff2',
  display: 'swap',
  variable: '--neue-plak-font',
});

export const ContactFlagButton = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const router = useRouter();

  const handleResize = () => {
    if (window.innerWidth < 1280) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const asPath = usePathname();
  const isOnContactPage = asPath === '/contact';

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  });

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const routeToContactPage = () => {
    router.push('/contact');
  };

  if (isOnContactPage) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <div className={styles.flagButton} onClick={routeToContactPage}>
          <p>Contact</p>
          <Icon name="Email" />
        </div>
      ) : (
        <Drawer.Root
          shouldScaleBackground
          direction={isMobile ? 'bottom' : 'right'}
          open={open}
          onOpenChange={setOpen}
        >
          <Drawer.Trigger asChild onClick={toggleDrawer}>
            <div className={styles.flagButton}>
              <p>Contact</p>
              <Icon name="Email" />
            </div>
          </Drawer.Trigger>

          <Drawer.Portal>
            <Drawer.Overlay
              className={styles.drawerOverlay}
              onClick={toggleDrawer}
            />
            <Drawer.Content
              className={`${styles.drawerContent} ${inter.variable} ${neuePlak.variable}`}
            >
              <div className={styles.drawerHeader}>
                {isMobile && <Drawer.Handle className={styles.drawerHandle} />}
                <Drawer.Title className={styles.drawerTitle}>
                  <Typography variant="heading3-regular" tag="h3">
                    Get in touch
                  </Typography>
                  <IconButton
                    icon="Cross"
                    style="muted"
                    shape="circle"
                    withoutPadding
                    onClick={toggleDrawer}
                  />
                </Drawer.Title>
              </div>
              <div className={styles.drawerForms}>
                <GetInTouch drawer={true} onClick={toggleDrawer} />
              </div>
            </Drawer.Content>
          </Drawer.Portal>
        </Drawer.Root>
      )}
    </>
  );
};
