import { formatPhoneNumber } from 'helpers/formatPhoneNumber';
import { Address, MenuItem as NavItem } from '@/types/misc';

import { MenuItem } from './menuItem';
import styles from './slideInMenu.module.scss';

type SlideInMenuProps = {
  extendedMenuItems: NavItem[];
  isOpen: boolean;
  address: Address;
  closeMenu: () => void;
};

export function SlideInMenu({
  extendedMenuItems,
  isOpen,
  address,
  closeMenu,
}: SlideInMenuProps) {
  const navItems = extendedMenuItems.map(({ id, attributes, vacancyCount }) => (
    <MenuItem
      key={id}
      name={attributes.title.toLocaleLowerCase()}
      href={attributes.slug}
      amount={vacancyCount ?? 0}
      surface="inverted"
      type="menu"
      onClick={closeMenu}
    />
  ));

  return (
    <div className={styles.container}>
      <div className={`${styles.wrapper} ${isOpen ? styles.show : ''}`}>
        <div
          className={`${styles.animatedContainer} ${
            isOpen ? styles.openAnimation : styles.closeAnimation
          }`}
        >
          <div className={styles.menuWrapper}>
            <ul className={styles.menuItemsContainer}>{navItems}</ul>
            <div className={styles.addressWrapper}>
              <address className={styles.address}>
                <span className={styles.addressLabel}>Adres</span>
                <span>{address.addressLine1}</span>
                <span>{address.addressLine2}</span>
              </address>
              <div className={styles.address}>
                <span className={styles.addressLabel}>Contact</span>
                <span>
                  <a href={`tel:${address.phoneNumber}`}>
                    {formatPhoneNumber(address.phoneNumber)}
                  </a>
                </span>
                <span>
                  <a href={`mailto:${address.emailAddress}`}>
                    {address.emailAddress}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.backdrop} ${isOpen ? styles.open : ''}`} />
    </div>
  );
}
